/*
 * Copyright 2021 Future Data Flows, LLC.  All Rights Reserved.
 */

import React from 'react';

import '../css/App.css';

import Dashboard from './Dashboard';

/**
 * Main application class
 */
class App extends React.Component {

    /**
     * Constructor for the main application class
     * @param {*} props - constructor parameters
     */
    constructor(props) {

        super(props);

        this.state = {
            auth: '',
            message: ''
        };

        this.setMessage = this.setMessage.bind(this);

        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
        });
    }

    /**
     * The event is invoked immediately before mounting occurs. 
     * It is called before render(), therefore setting state synchronously in this method will not trigger a re-rendering. 
     * Avoid introducing any side-effects or subscriptions in this method.
     */
    componentWillMount() {
        console.log();
    }

    /**
     * The event is invoked immediately after a component is mounted. 
     * Initialization that requires DOM nodes should go here. 
     * If you need to load data from a remote endpoint, this is a good place to instantiate the network request. 
     * Setting state in this method will trigger a re-rendering.
     */
    componentDidMount() {
        console.log();
    }

    setMessage(s) {        
        this.setState({ message: s});
    }

    /**
     * The function is a root renderer
     */
    render() {
        return (<div className="App">
            <Dashboard auth={this.state.auth}/>
        </div >);
    }

}

export default App;
