import React from 'react';
import Grid from '@material-ui/core/Grid';
import Language from '@material-ui/icons/Language';
import MailOutline from '@material-ui/icons/MailOutline';
import GitHub from '@material-ui/icons/GitHub';

import { ReactComponent as ExternalLinkIcon } from '../images/external-link.svg'

import Sample01 from '../images/sample01.png'
import Sample02 from '../images/sample02.png'
import Sample03 from '../images/sample03.png'
import Sample04 from '../images/sample04.png'
import Sample05 from '../images/sample05.png'
import Sample06 from '../images/sample06.png'
import Sample07 from '../images/sample07.png'
import Sample08 from '../images/sample08.png'
import Sample09 from '../images/sample09.png'
import Sample10 from '../images/sample10.png'
import Sample11 from '../images/sample11.png'
import Sample12 from '../images/sample12.png'
import Sample13 from '../images/sample13.png'
import Sample14 from '../images/sample14.png'
import Sample15 from '../images/sample15.png'
import Sample16 from '../images/sample16.png'
import Sample17 from '../images/sample17.png'
import Sample18 from '../images/sample18.png'
import Sample19 from '../images/sample19.png'
import Sample20 from '../images/sample20.png'
import Sample21 from '../images/sample21.png'
import Sample22 from '../images/sample22.png'
import Sample23 from '../images/sample23.png'
import Sample24 from '../images/sample24.png'
import Graph01 from '../images/graph01.png'

class FraudDetection extends React.Component {

    static get photoStyle() {
        return {
            width: (window.innerWidth / 4) + "px",
            padding: "10px"
        }
    }

    static get textStyle() {
        return {
            padding: "10px",
            fontSize: "1rem"
        }
    }

    static get externalLinkStyle() {
        return {
            height: 16,
            width: 16,
            padding: 0,
            margin: 0,
        }
    }

    static get linkStyle() {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    }

    render() {

        return (
            <div>
                <Grid container>
                    <Grid item xs={4}>
                        <a href="https://fdf.ai" target="_blank" style={FraudDetection.linkStyle}>
                            <Language /> https://fdf.ai <ExternalLinkIcon style={FraudDetection.externalLinkStyle} />
                        </a>
                    </Grid>
                    <Grid item xs={4}>
                        <a href="mailto:service@fdf.ai" target="_blank" style={FraudDetection.linkStyle}>
                            <MailOutline /> service@fdf.ai <ExternalLinkIcon style={FraudDetection.externalLinkStyle} />
                        </a>
                    </Grid>
                    <Grid item xs={4}>
                        <a href="https://github.com/valgarn/fraud-detection-framework" target="_blank" style={FraudDetection.linkStyle}>
                            <GitHub /> https://github.com/valgarn/fraud-detection-framework <ExternalLinkIcon style={FraudDetection.externalLinkStyle} />
                        </a>
                    </Grid>
                </Grid>

                <div style={FraudDetection.textStyle}>
                    <p style={FraudDetection.textStyle}>
                        FDF can be used everywhere wherever trust to user’s data is required.
                        The faked photos detection methods can be applied to banking documents, IDs, certificates, incidents proof etc.
                        The project is cross platform framework.
                    </p>
                </div>

                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample01} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample12} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample21} />


                <div style={FraudDetection.textStyle}>
                    <p style={FraudDetection.textStyle}>
                        Currently FDF have a good rate of faked photos detection.
                        Errors are happening but can be avoided if false-positives threshold will be increased.
                        In this case only one parameter should be changed.
                    </p>
                    <p style={FraudDetection.textStyle}>
                        Suspicious images have undefined probability of fake, because FDF cannot apply any method.
                    <ol>
                            <li>
                                Without suspicious
                            <ul>
                                    <li>Recall: 0.8</li>
                                    <li>PreciÎsion: 0.98</li>
                                    <li>F1-score: 0.88</li>
                                </ul>
                            </li>
                            <li>
                                Including suspicious
                            <ul>
                                    <li>Recall: 0.84</li>
                                    <li>Precision: 0.99</li>
                                    <li>F1-score: 0.91</li>
                                </ul>
                            </li>
                        </ol>
                    </p>
                </div>

                <div style={FraudDetection.linkStyle}>
                    <img alt="Detected" src={Graph01} />
                </div>

                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample04} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample05} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample06} />

                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample07} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample08} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample09} />

                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample10} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample11} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample16} />

                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample13} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample14} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample15} />

                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample02} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample17} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample18} />

                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample19} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample03} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample20} />

                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample22} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample23} />
                <img alt="Real sample" style={FraudDetection.photoStyle} src={Sample24} />

                <div style={FraudDetection.linkStyle}>
                    <p style={FraudDetection.textStyle}>
                        ©2019–2021 Future Data Flows, LLC.
                    </p>
                    <p style={FraudDetection.textStyle}>
                        All Rights Reserved.
                    </p>
                </div>

            </div >

        )
    }
}

export default FraudDetection;
