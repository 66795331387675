import React from 'react';
import Grid from '@material-ui/core/Grid';
import Language from '@material-ui/icons/Language';
import MailOutline from '@material-ui/icons/MailOutline';
import GitHub from '@material-ui/icons/GitHub';

import { ReactComponent as ExternalLinkIcon } from '../images/external-link.svg'

class About extends React.Component {

    static get textStyle() {
        return {
            padding: "10px",
            fontSize: "1rem"
        }
    }

    static get externalLinkStyle() {
        return {
            height: 16,
            width: 16,
            padding: 0,
            margin: 0,
        }
    }

    static get linkStyle() {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }
    }

    render() {

        return (
            <div>
                <Grid container>
                    <Grid item xs={4}>
                        <a href="https://fdf.ai" target="_blank" style={About.linkStyle}>
                            <Language /> https://fdf.ai <ExternalLinkIcon style={About.externalLinkStyle} />
                        </a>
                    </Grid>
                    <Grid item xs={4}>
                        <a href="mailto:service@fdf.ai" target="_blank" style={About.linkStyle}>
                            <MailOutline /> service@fdf.ai <ExternalLinkIcon style={About.externalLinkStyle} />
                        </a>
                    </Grid>
                    <Grid item xs={4}>
                        <a href="https://github.com/valgarn/fraud-detection-framework" target="_blank" style={About.linkStyle}>
                            <GitHub /> https://github.com/valgarn/fraud-detection-framework <ExternalLinkIcon style={About.externalLinkStyle} />
                        </a>
                    </Grid>
                </Grid>

                <div style={About.textStyle}>
                    <p style={About.textStyle}>
                        ©2019–2021 Future Data Flows, LLC. All Rights Reserved.
                    </p>
                </div>
            </div >

        )
    }
}

export default About;
